'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports['default'] = clear;
/**
 * Removes every property of the object.
 *
 * @param {Object} obj
 */

function clear(obj) {
  for (var prop in obj) {
    delete obj[prop];
  }
}
module.exports = exports['default'];