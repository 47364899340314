'use strict';

var _classCallCheck = require('babel-runtime/helpers/class-call-check')['default'];
Object.defineProperty(exports, '__esModule', {
  value: true
});
var JSNetworkXException = function JSNetworkXException(message) {
  _classCallCheck(this, JSNetworkXException);
  this.name = 'JSNetworkXException';
  this.message = message;
};
exports['default'] = JSNetworkXException;
module.exports = exports['default'];