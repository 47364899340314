'use strict';

var _interopRequireDefault = require('babel-runtime/helpers/interop-require-default')['default'];
var _defaults = require('babel-runtime/helpers/defaults')['default'];
var _interopRequireWildcard = require('babel-runtime/helpers/interop-require-wildcard')['default'];
Object.defineProperty(exports, '__esModule', {
  value: true
});
var _svg = require('./svg');
var _svg2 = _interopRequireDefault(_svg);
exports.svg = _svg2['default'];
_defaults(exports, _interopRequireWildcard(_svg));