//This file is automatically rebuilt by the Cesium build process.
import czm_degreesPerRadian from './Constants/degreesPerRadian.js';
import czm_depthRange from './Constants/depthRange.js';
import czm_epsilon1 from './Constants/epsilon1.js';
import czm_epsilon2 from './Constants/epsilon2.js';
import czm_epsilon3 from './Constants/epsilon3.js';
import czm_epsilon4 from './Constants/epsilon4.js';
import czm_epsilon5 from './Constants/epsilon5.js';
import czm_epsilon6 from './Constants/epsilon6.js';
import czm_epsilon7 from './Constants/epsilon7.js';
import czm_infinity from './Constants/infinity.js';
import czm_oneOverPi from './Constants/oneOverPi.js';
import czm_oneOverTwoPi from './Constants/oneOverTwoPi.js';
import czm_passCesium3DTile from './Constants/passCesium3DTile.js';
import czm_passCesium3DTileClassification from './Constants/passCesium3DTileClassification.js';
import czm_passCesium3DTileClassificationIgnoreShow from './Constants/passCesium3DTileClassificationIgnoreShow.js';
import czm_passClassification from './Constants/passClassification.js';
import czm_passCompute from './Constants/passCompute.js';
import czm_passEnvironment from './Constants/passEnvironment.js';
import czm_passGlobe from './Constants/passGlobe.js';
import czm_passOpaque from './Constants/passOpaque.js';
import czm_passOverlay from './Constants/passOverlay.js';
import czm_passTerrainClassification from './Constants/passTerrainClassification.js';
import czm_passTranslucent from './Constants/passTranslucent.js';
import czm_pi from './Constants/pi.js';
import czm_piOverFour from './Constants/piOverFour.js';
import czm_piOverSix from './Constants/piOverSix.js';
import czm_piOverThree from './Constants/piOverThree.js';
import czm_piOverTwo from './Constants/piOverTwo.js';
import czm_radiansPerDegree from './Constants/radiansPerDegree.js';
import czm_sceneMode2D from './Constants/sceneMode2D.js';
import czm_sceneMode3D from './Constants/sceneMode3D.js';
import czm_sceneModeColumbusView from './Constants/sceneModeColumbusView.js';
import czm_sceneModeMorphing from './Constants/sceneModeMorphing.js';
import czm_solarRadius from './Constants/solarRadius.js';
import czm_threePiOver2 from './Constants/threePiOver2.js';
import czm_twoPi from './Constants/twoPi.js';
import czm_webMercatorMaxLatitude from './Constants/webMercatorMaxLatitude.js';
import czm_depthRangeStruct from './Structs/depthRangeStruct.js';
import czm_material from './Structs/material.js';
import czm_materialInput from './Structs/materialInput.js';
import czm_ray from './Structs/ray.js';
import czm_raySegment from './Structs/raySegment.js';
import czm_shadowParameters from './Structs/shadowParameters.js';
import czm_acesTonemapping from './Functions/acesTonemapping.js';
import czm_alphaWeight from './Functions/alphaWeight.js';
import czm_antialias from './Functions/antialias.js';
import czm_approximateSphericalCoordinates from './Functions/approximateSphericalCoordinates.js';
import czm_branchFreeTernary from './Functions/branchFreeTernary.js';
import czm_cascadeColor from './Functions/cascadeColor.js';
import czm_cascadeDistance from './Functions/cascadeDistance.js';
import czm_cascadeMatrix from './Functions/cascadeMatrix.js';
import czm_cascadeWeights from './Functions/cascadeWeights.js';
import czm_columbusViewMorph from './Functions/columbusViewMorph.js';
import czm_computePosition from './Functions/computePosition.js';
import czm_cosineAndSine from './Functions/cosineAndSine.js';
import czm_decompressTextureCoordinates from './Functions/decompressTextureCoordinates.js';
import czm_depthClampFarPlane from './Functions/depthClampFarPlane.js';
import czm_eastNorthUpToEyeCoordinates from './Functions/eastNorthUpToEyeCoordinates.js';
import czm_ellipsoidContainsPoint from './Functions/ellipsoidContainsPoint.js';
import czm_ellipsoidWgs84TextureCoordinates from './Functions/ellipsoidWgs84TextureCoordinates.js';
import czm_equalsEpsilon from './Functions/equalsEpsilon.js';
import czm_eyeOffset from './Functions/eyeOffset.js';
import czm_eyeToWindowCoordinates from './Functions/eyeToWindowCoordinates.js';
import czm_fastApproximateAtan from './Functions/fastApproximateAtan.js';
import czm_fog from './Functions/fog.js';
import czm_gammaCorrect from './Functions/gammaCorrect.js';
import czm_geodeticSurfaceNormal from './Functions/geodeticSurfaceNormal.js';
import czm_getDefaultMaterial from './Functions/getDefaultMaterial.js';
import czm_getLambertDiffuse from './Functions/getLambertDiffuse.js';
import czm_getSpecular from './Functions/getSpecular.js';
import czm_getWaterNoise from './Functions/getWaterNoise.js';
import czm_HSBToRGB from './Functions/HSBToRGB.js';
import czm_HSLToRGB from './Functions/HSLToRGB.js';
import czm_hue from './Functions/hue.js';
import czm_inverseGamma from './Functions/inverseGamma.js';
import czm_isEmpty from './Functions/isEmpty.js';
import czm_isFull from './Functions/isFull.js';
import czm_latitudeToWebMercatorFraction from './Functions/latitudeToWebMercatorFraction.js';
import czm_lineDistance from './Functions/lineDistance.js';
import czm_luminance from './Functions/luminance.js';
import czm_metersPerPixel from './Functions/metersPerPixel.js';
import czm_modelToWindowCoordinates from './Functions/modelToWindowCoordinates.js';
import czm_multiplyWithColorBalance from './Functions/multiplyWithColorBalance.js';
import czm_nearFarScalar from './Functions/nearFarScalar.js';
import czm_octDecode from './Functions/octDecode.js';
import czm_packDepth from './Functions/packDepth.js';
import czm_phong from './Functions/phong.js';
import czm_planeDistance from './Functions/planeDistance.js';
import czm_pointAlongRay from './Functions/pointAlongRay.js';
import czm_rayEllipsoidIntersectionInterval from './Functions/rayEllipsoidIntersectionInterval.js';
import czm_readDepth from './Functions/readDepth.js';
import czm_readNonPerspective from './Functions/readNonPerspective.js';
import czm_reverseLogDepth from './Functions/reverseLogDepth.js';
import czm_RGBToHSB from './Functions/RGBToHSB.js';
import czm_RGBToHSL from './Functions/RGBToHSL.js';
import czm_RGBToXYZ from './Functions/RGBToXYZ.js';
import czm_sampleOctahedralProjection from './Functions/sampleOctahedralProjection.js';
import czm_saturation from './Functions/saturation.js';
import czm_shadowDepthCompare from './Functions/shadowDepthCompare.js';
import czm_shadowVisibility from './Functions/shadowVisibility.js';
import czm_signNotZero from './Functions/signNotZero.js';
import czm_sphericalHarmonics from './Functions/sphericalHarmonics.js';
import czm_tangentToEyeSpaceMatrix from './Functions/tangentToEyeSpaceMatrix.js';
import czm_transformPlane from './Functions/transformPlane.js';
import czm_translateRelativeToEye from './Functions/translateRelativeToEye.js';
import czm_translucentPhong from './Functions/translucentPhong.js';
import czm_transpose from './Functions/transpose.js';
import czm_unpackDepth from './Functions/unpackDepth.js';
import czm_unpackFloat from './Functions/unpackFloat.js';
import czm_vertexLogDepth from './Functions/vertexLogDepth.js';
import czm_windowToEyeCoordinates from './Functions/windowToEyeCoordinates.js';
import czm_writeDepthClampedToFarPlane from './Functions/writeDepthClampedToFarPlane.js';
import czm_writeLogDepth from './Functions/writeLogDepth.js';
import czm_writeNonPerspective from './Functions/writeNonPerspective.js';
import czm_XYZToRGB from './Functions/XYZToRGB.js';
export default {
  czm_degreesPerRadian: czm_degreesPerRadian,
  czm_depthRange: czm_depthRange,
  czm_epsilon1: czm_epsilon1,
  czm_epsilon2: czm_epsilon2,
  czm_epsilon3: czm_epsilon3,
  czm_epsilon4: czm_epsilon4,
  czm_epsilon5: czm_epsilon5,
  czm_epsilon6: czm_epsilon6,
  czm_epsilon7: czm_epsilon7,
  czm_infinity: czm_infinity,
  czm_oneOverPi: czm_oneOverPi,
  czm_oneOverTwoPi: czm_oneOverTwoPi,
  czm_passCesium3DTile: czm_passCesium3DTile,
  czm_passCesium3DTileClassification: czm_passCesium3DTileClassification,
  czm_passCesium3DTileClassificationIgnoreShow: czm_passCesium3DTileClassificationIgnoreShow,
  czm_passClassification: czm_passClassification,
  czm_passCompute: czm_passCompute,
  czm_passEnvironment: czm_passEnvironment,
  czm_passGlobe: czm_passGlobe,
  czm_passOpaque: czm_passOpaque,
  czm_passOverlay: czm_passOverlay,
  czm_passTerrainClassification: czm_passTerrainClassification,
  czm_passTranslucent: czm_passTranslucent,
  czm_pi: czm_pi,
  czm_piOverFour: czm_piOverFour,
  czm_piOverSix: czm_piOverSix,
  czm_piOverThree: czm_piOverThree,
  czm_piOverTwo: czm_piOverTwo,
  czm_radiansPerDegree: czm_radiansPerDegree,
  czm_sceneMode2D: czm_sceneMode2D,
  czm_sceneMode3D: czm_sceneMode3D,
  czm_sceneModeColumbusView: czm_sceneModeColumbusView,
  czm_sceneModeMorphing: czm_sceneModeMorphing,
  czm_solarRadius: czm_solarRadius,
  czm_threePiOver2: czm_threePiOver2,
  czm_twoPi: czm_twoPi,
  czm_webMercatorMaxLatitude: czm_webMercatorMaxLatitude,
  czm_depthRangeStruct: czm_depthRangeStruct,
  czm_material: czm_material,
  czm_materialInput: czm_materialInput,
  czm_ray: czm_ray,
  czm_raySegment: czm_raySegment,
  czm_shadowParameters: czm_shadowParameters,
  czm_acesTonemapping: czm_acesTonemapping,
  czm_alphaWeight: czm_alphaWeight,
  czm_antialias: czm_antialias,
  czm_approximateSphericalCoordinates: czm_approximateSphericalCoordinates,
  czm_branchFreeTernary: czm_branchFreeTernary,
  czm_cascadeColor: czm_cascadeColor,
  czm_cascadeDistance: czm_cascadeDistance,
  czm_cascadeMatrix: czm_cascadeMatrix,
  czm_cascadeWeights: czm_cascadeWeights,
  czm_columbusViewMorph: czm_columbusViewMorph,
  czm_computePosition: czm_computePosition,
  czm_cosineAndSine: czm_cosineAndSine,
  czm_decompressTextureCoordinates: czm_decompressTextureCoordinates,
  czm_depthClampFarPlane: czm_depthClampFarPlane,
  czm_eastNorthUpToEyeCoordinates: czm_eastNorthUpToEyeCoordinates,
  czm_ellipsoidContainsPoint: czm_ellipsoidContainsPoint,
  czm_ellipsoidWgs84TextureCoordinates: czm_ellipsoidWgs84TextureCoordinates,
  czm_equalsEpsilon: czm_equalsEpsilon,
  czm_eyeOffset: czm_eyeOffset,
  czm_eyeToWindowCoordinates: czm_eyeToWindowCoordinates,
  czm_fastApproximateAtan: czm_fastApproximateAtan,
  czm_fog: czm_fog,
  czm_gammaCorrect: czm_gammaCorrect,
  czm_geodeticSurfaceNormal: czm_geodeticSurfaceNormal,
  czm_getDefaultMaterial: czm_getDefaultMaterial,
  czm_getLambertDiffuse: czm_getLambertDiffuse,
  czm_getSpecular: czm_getSpecular,
  czm_getWaterNoise: czm_getWaterNoise,
  czm_HSBToRGB: czm_HSBToRGB,
  czm_HSLToRGB: czm_HSLToRGB,
  czm_hue: czm_hue,
  czm_inverseGamma: czm_inverseGamma,
  czm_isEmpty: czm_isEmpty,
  czm_isFull: czm_isFull,
  czm_latitudeToWebMercatorFraction: czm_latitudeToWebMercatorFraction,
  czm_lineDistance: czm_lineDistance,
  czm_luminance: czm_luminance,
  czm_metersPerPixel: czm_metersPerPixel,
  czm_modelToWindowCoordinates: czm_modelToWindowCoordinates,
  czm_multiplyWithColorBalance: czm_multiplyWithColorBalance,
  czm_nearFarScalar: czm_nearFarScalar,
  czm_octDecode: czm_octDecode,
  czm_packDepth: czm_packDepth,
  czm_phong: czm_phong,
  czm_planeDistance: czm_planeDistance,
  czm_pointAlongRay: czm_pointAlongRay,
  czm_rayEllipsoidIntersectionInterval: czm_rayEllipsoidIntersectionInterval,
  czm_readDepth: czm_readDepth,
  czm_readNonPerspective: czm_readNonPerspective,
  czm_reverseLogDepth: czm_reverseLogDepth,
  czm_RGBToHSB: czm_RGBToHSB,
  czm_RGBToHSL: czm_RGBToHSL,
  czm_RGBToXYZ: czm_RGBToXYZ,
  czm_sampleOctahedralProjection: czm_sampleOctahedralProjection,
  czm_saturation: czm_saturation,
  czm_shadowDepthCompare: czm_shadowDepthCompare,
  czm_shadowVisibility: czm_shadowVisibility,
  czm_signNotZero: czm_signNotZero,
  czm_sphericalHarmonics: czm_sphericalHarmonics,
  czm_tangentToEyeSpaceMatrix: czm_tangentToEyeSpaceMatrix,
  czm_transformPlane: czm_transformPlane,
  czm_translateRelativeToEye: czm_translateRelativeToEye,
  czm_translucentPhong: czm_translucentPhong,
  czm_transpose: czm_transpose,
  czm_unpackDepth: czm_unpackDepth,
  czm_unpackFloat: czm_unpackFloat,
  czm_vertexLogDepth: czm_vertexLogDepth,
  czm_windowToEyeCoordinates: czm_windowToEyeCoordinates,
  czm_writeDepthClampedToFarPlane: czm_writeDepthClampedToFarPlane,
  czm_writeLogDepth: czm_writeLogDepth,
  czm_writeNonPerspective: czm_writeNonPerspective,
  czm_XYZToRGB: czm_XYZToRGB
};